"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.weekDaysOpeningHours = exports.weekDays = exports.toISOLocal = exports.secondsToString = exports.secondsToMinutes = exports.secondsToHours = exports.periodsToSecondsFactor = exports.monthAbbr = exports.maxDateRangeThreshold = exports.isTimeGreatedThan = exports.isSameDay = exports.getTimeFromDate = exports.getNextDaysArray = exports.getMonthIndexFromAbbr = exports.getMonthAbbrFromDate = exports.getFullTimeFromDate = exports.getDateArray = exports.generateTimePeriods = exports.fullWeekDays = exports.formatFormObjectToDate = exports.formatDateToWeekday = exports.formatDateToMonthday = exports.formatDateToISO = exports.formatDateToFormObject = exports.formatDateToForm = exports.formatDateObjToCommonString = exports.formatArrayOfTimesToOpeningHours = exports.fixUTCDateString = exports.dateWithoutHours = exports.dateToStandardRequestParamsArray = exports.datePlusDays = exports.dateOnInterval = exports.dateDiffInDays = exports.convertSecondsToPeriod = exports.convertPeriodToSeconds = exports.checkDateOnInterval = exports.ISOToDateTimeString = exports.ISOToDateString = void 0;
var _dateFns = require("date-fns");
var periodsToSecondsFactor = exports.periodsToSecondsFactor = Object.freeze({
  hours: 3600,
  days: 86400,
  weeks: 86400 * 7,
  months: 86400 * 30
});
var weekDays = exports.weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
var weekDaysOpeningHours = exports.weekDaysOpeningHours = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
var fullWeekDays = exports.fullWeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
var monthAbbr = exports.monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
var maxDateRangeThreshold = exports.maxDateRangeThreshold = 88;
var dateOnInterval = exports.dateOnInterval = Object.freeze({
  BEFORE: 'before',
  BETWEEN: 'between',
  AFTER: 'after'
});

/**
 * Assign the project to an employee.
 * @param {Date} date - Date to be compared
 * @param {Date} firstDate - First date of interval
 * @param {Date} secondDate - Last date of interval
 * @return {string} - A string on dateOnInterval
 */
var checkDateOnInterval = exports.checkDateOnInterval = function checkDateOnInterval(date, firstDate, secondDate) {
  var d = new Date(date);
  var d1 = new Date(firstDate);
  var d2 = new Date(secondDate);
  if ((0, _dateFns.isBefore)(d, d1)) {
    return dateOnInterval.BEFORE;
  }
  if ((0, _dateFns.isAfter)(d, d1) && (0, _dateFns.isBefore)(d, d2)) {
    return dateOnInterval.BETWEEN;
  }
  return dateOnInterval.AFTER;
};
var formatArrayOfTimesToOpeningHours = exports.formatArrayOfTimesToOpeningHours = function formatArrayOfTimesToOpeningHours(arr) {
  var str = '';
  arr.map(function (item, idx) {
    var separator = idx % 2 === 0 ? '-' : ',';
    str = "".concat(str).concat(item).concat(separator);
  });
  return str.slice(0, -1);
};
var ISOToDateTimeString = exports.ISOToDateTimeString = function ISOToDateTimeString(date) {
  var JSDate = new Date(date);
  return "".concat(JSDate.toLocaleDateString(), " at ").concat(JSDate.toLocaleTimeString());
};
var ISOToDateString = exports.ISOToDateString = function ISOToDateString(date) {
  return ISOToDateTimeString(date).split('at')[0];
};
var formatDateToISO = exports.formatDateToISO = function formatDateToISO(date) {
  return date ? new Date(date).toISOString().split('T')[0] : null;
};
var formatDateToWeekday = exports.formatDateToWeekday = function formatDateToWeekday(date) {
  return weekDays[new Date(date).getDay()];
};
var formatDateToMonthday = exports.formatDateToMonthday = function formatDateToMonthday(date) {
  var initializedDate = new Date(date);
  return "".concat(initializedDate.getMonth() + 1, "/").concat(initializedDate.getDate());
};
var getMonthAbbrFromDate = exports.getMonthAbbrFromDate = function getMonthAbbrFromDate(date) {
  var initializedDate = new Date(date);
  return monthAbbr[initializedDate.getMonth()];
};
var getMonthIndexFromAbbr = exports.getMonthIndexFromAbbr = function getMonthIndexFromAbbr(month) {
  return monthAbbr.indexOf(month) + 1;
};
var formatDateObjToCommonString = exports.formatDateObjToCommonString = function formatDateObjToCommonString(dateObj) {
  return "".concat(monthAbbr[dateObj.month - 1], " ").concat(dateObj.day, ", ").concat(dateObj.year);
};
var formatDateToForm = exports.formatDateToForm = function formatDateToForm(date) {
  var d = new Date(date),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};
var formatDateToFormObject = exports.formatDateToFormObject = function formatDateToFormObject(date) {
  var d = new Date(date),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear();
  return {
    day: day,
    month: month,
    year: year
  };
};
var formatFormObjectToDate = exports.formatFormObjectToDate = function formatFormObjectToDate(_ref) {
  var day = _ref.day,
    month = _ref.month,
    year = _ref.year;
  if (day && month && year) {
    return new Date(year, month - 1, day);
  }
  console.error('Error parsing date to object.');
  return new Date();
};
var fixUTCDateString = exports.fixUTCDateString = function fixUTCDateString(date) {
  var obj = formatDateToFormObject(date);
  return new Date(obj.year, obj.month - 1, obj.day);
};
var dateToStandardRequestParamsArray = exports.dateToStandardRequestParamsArray = function dateToStandardRequestParamsArray(date) {
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'date';
  var dateObj = formatDateToFormObject(date);
  return Object.fromEntries([["".concat(name, "[day]"), dateObj.day], ["".concat(name, "[month]"), dateObj.month], ["".concat(name, "[year]"), dateObj.year]]);
};
var datePlusDays = exports.datePlusDays = function datePlusDays(date, days) {
  var parsedDate = new Date(date);
  parsedDate.setDate(date.getDate() + days);
  return parsedDate;
};
var dateWithoutHours = exports.dateWithoutHours = function dateWithoutHours(date) {
  var parsedDate = new Date(date);
  return new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate());
};
var secondsToHours = exports.secondsToHours = function secondsToHours(secs) {
  return new Date(secs * 1000).toISOString().substr(11, 8);
};
var secondsToMinutes = exports.secondsToMinutes = function secondsToMinutes(secs) {
  return new Date(secs * 1000).toISOString().substr(14, 5);
};
var getTimeFromDate = exports.getTimeFromDate = function getTimeFromDate(date) {
  return new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};
var dateDiffInDays = exports.dateDiffInDays = function dateDiffInDays(date1, date2) {
  var dt1 = new Date(date1);
  var dt2 = new Date(date2);
  return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
};
var getFullTimeFromDate = exports.getFullTimeFromDate = function getFullTimeFromDate(date) {
  var parsedDate = new Date(date);
  var hours = parsedDate.getHours();
  var minutes = parsedDate.getMinutes();
  hours = hours < 10 ? "0".concat(hours) : hours;
  minutes = minutes < 10 ? "0".concat(minutes) : minutes;
  return "".concat(hours, ":").concat(minutes);
};
var getNextDaysArray = exports.getNextDaysArray = function getNextDaysArray() {
  var startDate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
  var numDays = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 7;
  var dateArr = [];
  var fixedDate = dateWithoutHours(startDate);
  for (var i = 0; i < numDays; i++) {
    var dateNewInstance = new Date(fixedDate);
    dateNewInstance.setDate(fixedDate.getDate() + i);
    dateArr.push(dateNewInstance);
  }
  return dateArr;
};
var getDateArray = exports.getDateArray = function getDateArray(start, end) {
  var fixedStart = dateWithoutHours(start);
  var fixedEnd = dateWithoutHours(end);
  var dateArr = [];
  while (fixedStart <= fixedEnd) {
    dateArr.push(new Date(fixedStart));
    fixedStart.setDate(fixedStart.getDate() + 1);
  }
  return dateArr;
};
var generateTimePeriods = exports.generateTimePeriods = function generateTimePeriods() {
  var period = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 30;
  var times = [];
  var ap = ['AM', 'PM'];
  var tt = 0;
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = "".concat(hh === 0 || hh === 12 ? '12' : (hh % 12).toString().slice(-2), ":").concat(('0' + mm).slice(-2), " ").concat(ap[hh < 12 ? 0 : 1]); // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + period;
  }
  return times;
};
var isTimeGreatedThan = exports.isTimeGreatedThan = function isTimeGreatedThan(t1, t2) {
  if (t1 > t2) return true;else return false;
};
var toISOLocal = exports.toISOLocal = function toISOLocal(d) {
  var zoned = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var date = new Date(d);
  var z = function z(n) {
    return ('0' + n).slice(-2);
  };
  var zz = function zz(n) {
    return ('00' + n).slice(-3);
  };
  return date.getFullYear() + '-' + z(date.getMonth() + 1) + '-' + z(date.getDate()) + 'T' + z(date.getHours()) + ':' + z(date.getMinutes()) + (zoned ? 'Z' : '');
};
var secondsToString = exports.secondsToString = function secondsToString(s) {
  var _intervalToDuration = (0, _dateFns.intervalToDuration)({
      start: 0,
      end: s * 1000
    }),
    years = _intervalToDuration.years,
    months = _intervalToDuration.months,
    days = _intervalToDuration.days,
    _intervalToDuration$w = _intervalToDuration.weeks,
    weeks = _intervalToDuration$w === void 0 ? Math.floor(days / 7) : _intervalToDuration$w,
    hours = _intervalToDuration.hours,
    minutes = _intervalToDuration.minutes,
    seconds = _intervalToDuration.seconds;
  var getValueTerminology = function getValueTerminology(v) {
    return v === 1 ? '' : 's';
  };
  if (years > 0) {
    return "".concat(years, " Year").concat(getValueTerminology(years));
  }
  if (months > 0) {
    return "".concat(months, " Month").concat(getValueTerminology(months));
  }
  if (weeks > 0) {
    return "".concat(weeks, " Week").concat(getValueTerminology(weeks));
  }
  if (days > 0) {
    return "".concat(days, " Day").concat(getValueTerminology(days));
  }
  if (hours > 0) {
    return "".concat(hours, " Hour").concat(getValueTerminology(hours));
  }
  if (minutes > 0) {
    return "".concat(minutes, " Minute").concat(getValueTerminology(minutes));
  }
  return "".concat(seconds, " Second").concat(getValueTerminology(seconds));
};

/**
 * @param {int} value - Number of periods to convert
 * @param {periodsToSecondsFactor} period - Item from periods enum
 */
var convertPeriodToSeconds = exports.convertPeriodToSeconds = function convertPeriodToSeconds() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var period = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : periodsToSecondsFactor.hours;
  return value * period;
};

/**
 * @param {int} value - Number of periods to convert
 * @param {periodsToSecondsFactor} period - Item from periods enum
 */
var convertSecondsToPeriod = exports.convertSecondsToPeriod = function convertSecondsToPeriod() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var period = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : periodsToSecondsFactor.hours;
  return Math.ceil(value / period);
};
var isSameDay = exports.isSameDay = function isSameDay(dateA, dateB) {
  if (dateA && dateB) {
    return dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth() && dateA.getDate() === dateB.getDate();
  }
  return false;
};